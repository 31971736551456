import React from "react";

const GooglePlayButton = ({ url, className }) => {
  return (
    <a
      href={url}
      className={`flex items-center bg-black text-white px-3 py-1 rounded-lg hover:scale-110 transition-transform duration-300 dark:border ${className}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`${process.env.PUBLIC_URL}/google-play-icon.svg`}
        alt="Google Play Icon"
        className="w-6 h-6 mr-2"
      />
      <div className="flex flex-col">
        <span className="text-xs">GET IT ON</span>
        <span className="text-base font-bold">Google Play</span>
      </div>
    </a>
  );
};

export default GooglePlayButton;
