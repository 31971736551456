import React, { useContext } from "react";
import { ThemeContext } from "./ThemeContext";

const ThemeToggle = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <div
      onClick={toggleTheme}
      className={`w-16 h-8 flex items-center rounded-full p-1 cursor-pointer absolute top-5 left-5 ${
        theme === "light" ? "bg-gray-200" : "bg-gray-800"
      }`}
    >
      <div
        className={`w-6 h-6 bg-white rounded-full shadow-md transform duration-300 ease-in-out ${
          theme === "light" ? "translate-x-0" : "translate-x-8"
        }`}
      >
        {theme === "light" ? (
          <img
            src={`${process.env.PUBLIC_URL}/sun.svg`}
            alt="Sun Icon"
            className="w-full h-full p-1"
          />
        ) : (
          <img
            src={`${process.env.PUBLIC_URL}/moon.svg`}
            alt="Sun Icon"
            className="w-full h-full p-1"
          />
        )}
      </div>
    </div>
  );
};

export default ThemeToggle;
