import React from "react";
import { useTranslation } from "react-i18next";

const Founders = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full my-36 bg-white dark:bg-black flex flex-col items-center justify-center text-black dark:text-white py-8">
      <h1 className="text-3xl font-bold mb-6">{t("founders.meet_founders")}</h1>
      <div className="flex flex-col md:flex-row items-center justify-around w-full md:w-3/4 mx-auto">
        <div className="flex flex-col items-center p-4 md:p-8">
          <div className="w-48 h-48 rounded-full overflow-hidden mb-4">
            <img
              src={`${process.env.PUBLIC_URL}/founder1.jpg`}
              alt="Niki"
              className="w-full h-full object-cover"
            />
          </div>
          <h2 className="text-xl font-semibold mb-2">Niki</h2>
          <p className="text-center">{t("founders.founder1")}</p>
        </div>
        <div className="flex flex-col items-center p-4 md:p-8">
          <div className="w-48 h-48 rounded-full overflow-hidden mb-4">
            <img
              src={`${process.env.PUBLIC_URL}/founder2.jpg`}
              alt="Lorin Urbantat"
              className="w-full h-full object-cover"
            />
          </div>
          <h2 className="text-xl font-semibold mb-2">Lorin Urbantat</h2>
          <p className="text-center">{t("founders.founder2")}</p>
        </div>
      </div>
    </div>
  );
};

export default Founders;
