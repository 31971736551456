import React from "react";
import { useTranslation } from "react-i18next";
import GooglePlayButton from "./GooglePlayButton";
import AppStoreButton from "./AppStoreButton";

function Top() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center text-center py-10 ">
      <h1 className="text-3xl md:text-9xl font-black text-black dark:text-white">
        Flat-Finders
      </h1>
      <h2 className="text-xl md:text-3xl font-extrabold text-black dark:text-white mt-20">
        {t("subheading")}
      </h2>
      <div className="flex items-center space-x-10 mt-5">
        <GooglePlayButton url={"#"} />
        <AppStoreButton url={"#"} />
      </div>
    </div>
  );
}

export default Top;
