import React from "react";

const PhoneMockup = () => {
  return (
    <img
      className="absolute top-96 right-12 w-96 h-1/2 z-10 scale-125"
      src={`${process.env.PUBLIC_URL}/phone.svg`}
      title="Figma Mockup"
      alt="Phone Mockup"
    />
  );
};

export default PhoneMockup;
