import React, { useState, useEffect, useRef } from "react";
import ReactCountryFlag from "react-country-flag";
import options from "../data/languages.json";
import { useTranslation } from "react-i18next";

function LanguageSelector({ changeLanguage }) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const { i18n } = useTranslation();

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisible]);

  const handleLanguageChange = (value) => {
    setDropdownVisible(false);
    console.warn(value);
    changeLanguage(value);
  };

  const languageToCountryCode = (language) => {
    const mapping = {
      en: "GB",
      de: "DE",
    };

    return mapping[language] || "GB";
  };

  return (
    <div className="m-2 relative z-10 bg-red-50">
      <div className="absolute top-2 right-2 flex items-center font-thin">
        <button
          ref={buttonRef}
          className="flex items-center hover:scale-125 transition-transform duration-200"
          onClick={() => setDropdownVisible(!dropdownVisible)}
        >
          <ReactCountryFlag
            countryCode={languageToCountryCode(i18n.language)}
            svg
            style={{
              width: "32px",
              height: "auto",
              marginRight: "12px",
              borderRadius: "5px",
            }}
          />
        </button>
      </div>
      {dropdownVisible && (
        <div
          ref={dropdownRef}
          className="absolute right-2 mt-10 p-2 text-white rounded-xl shadow-lg z-20 max-h-96 overflow-y-auto whitespace-nowrap bg-black bg-opacity-70 backdrop-blur-sm w-36"
        >
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => handleLanguageChange(option.value)}
              className="flex items-center w-full text-left hover:bg-gray-200 hover:text-black cursor-pointer px-[12px] py-3 md:py-1"
            >
              <ReactCountryFlag
                countryCode={option.countryCode}
                svg
                style={{
                  width: "32px",
                  height: "auto",
                  marginRight: "12px",
                  borderRadius: "5px",
                }}
              />
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSelector;
