import React from "react";
import { useTranslation } from "react-i18next";

function Mission() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col py-10  h-full">
      <h1 className="text-2xl font-bold mb-4">{t("mission.title")}</h1>
      <ul className="list-disc list-inside space-y-10">
        <li>{t("mission.point1")}</li>
        <li>{t("mission.point2")}</li>
        <li>{t("mission.point3")}</li>
      </ul>
    </div>
  );
}

export default Mission;
