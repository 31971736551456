import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-black dark:bg-[#371B2A] py-10 text-white">
      <div className="container mx-auto flex flex-col justify-between items-center">
        <div className="mb-4 md:mb-0">
          <a href="/" className="text-xl font-bold">
            Flat-Finders
          </a>
        </div>
        <div className="mb-4 md:mb-0">
          {t("contact")}:  <a href="mailto:contact@flat-finders.com">contact@flat-finders.com</a>
        </div>
        <div>
          <p className="text-sm">
            &copy; 2024 Flat-Finders. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
