import React, { useEffect } from "react";
import Top from "./components/Top";
import Footer from "./components/Footer";
import LanguageSelector from "./components/Languageselector";
import Mission from "./components/Mission";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import ThemeToggle from "./components/ThemeToggle";
import { ThemeProvider } from "./components/ThemeContext";
import PhoneMockup from "./components/PhoneMockup";
import Team from "./components/Team";

function App() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    Cookies.set("language", lang, { sameSite: "None", secure: true });
  };

  useEffect(() => {
    const savedLanguage = Cookies.get("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <ThemeProvider>
      <div className="flex flex-col w-full relative bg-white dark:bg-black">
        <LanguageSelector changeLanguage={changeLanguage} />
        <ThemeToggle />
        <div className="relative w-full md:w-2/3 my-36 mx-auto">
          <Top />
        </div>

        <PhoneMockup />

        <div className="w-full my-36 bg-black dark:bg-[#371B2A] skew-y-3 flex flex-col items-center justify-center text-white py-10">
          <div className="w-full md:w-1/2 -skew-y-3 mx-auto">
            <Mission />
          </div>
        </div>

        <Team />

        <div className="w-full mt-20">
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
